const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://uinapxui16.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://zs3d5bt8t9.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.1',
    HOSTNAME: 'https://company.testing.laika.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.laika.forwoodsafety.com',
    WEBSOCKET: 'wss://gjxalnyldb.execute-api.us-west-2.amazonaws.com/testing'
  },
};

export default config;